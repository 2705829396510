<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    comingSoon: {
        type: Boolean,
        default: false,
    },
    beta: {
        type: Boolean,
        default: false,
    },
    new: {
        type: Boolean,
        default: false,
    },
})
</script>

<template>
    <article class="border bg-white rounded-3xl p-8 group shadow hover:shadow-lg cursor-default">
        <div v-if="props.comingSoon"
             class="relative">
            <div class="absolute right-0 -top-10 bg-indigo-700 text-slate-50 rounded px-4 py-2 -rotate-12 group-hover:rotate-12 transition duration-300">
                coming soon
            </div>
        </div>
        <div v-if="props.beta"
             class="relative">
            <div class="absolute right-0 -top-10 bg-yellow-700 text-slate-50 rounded px-4 py-2 -rotate-12 group-hover:rotate-12 transition duration-300">
                beta
            </div>
        </div>
        <div v-if="props.new"
             class="relative">
            <div class="absolute right-0 -top-10 bg-orange-700 text-slate-50 rounded px-4 py-2 -rotate-12 group-hover:animate-ping group-hover:bg-yellow-300 transition duration-300">
                new
            </div>
        </div>
        <div class="flex">
            <div class="pr-4"
                 :class="{
                    'text-slate-400 group-hover:text-indigo-500': !props.comingSoon && !props.beta,
                    'text-slate-300 group-hover:text-indigo-300': props.comingSoon,
                    'text-slate-300 group-hover:text-yellow-600': props.beta,
                 }"><slot name="icon" /></div>
            <div class="flex flex-col space-y-4 pr-4">
                <h3 class="text-xl font-bold tracking-tight text-slate-800 group-hover:text-indigo-700">{{ props.title }}</h3>
                <p class="prose group-hover:text-indigo-500"><slot /></p>
            </div>
        </div>
    </article>
</template>
