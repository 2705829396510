<script setup>
import {CheckIcon, LightBulbIcon} from '@heroicons/vue/24/outline/index.js';
import {usePage} from '@inertiajs/vue3';
import {ref} from 'vue';

const yearlyPrice = usePage().props.lnklst.yearlyPriceInCents / 100;
const monthlyPrice = usePage().props.lnklst.monthlyPriceInCents / 100;
const yearlyDiscount = Math.ceil((1 - (yearlyPrice / (monthlyPrice * 12))) * 100);
const currency = usePage().props.lnklst.currency;
const yearly = ref(true);

const includedFeatures = [
    'Unlimited teams',
    'Unlimited users per team',
    'No tracking',
    'GDPR compliant',
    '60 days money back guarantee',
];
</script>

<template>
    <section id="plans">
        <div class="py-24 sm:py-32 dark:bg-gradient-to-b dark:from-gray-900 dark:to-indigo-500 dark:rounded-3xl">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-2xl sm:text-center">
                    <h2 class="text-3xl font-bold tracking-tight text-slate-800 dark:text-gray-200 sm:text-4xl">Simple no-tricks pricing</h2>
                    <p class="mt-6 text-lg leading-8 text-slate-600 dark:text-gray-300">Flat rate pricing for linklist. Just use all features after paying.</p>
                </div>
                <div class="bg-white mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-slate-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div class="p-8 sm:p-10 lg:flex-auto">
                        <h3 class="text-2xl font-bold tracking-tight text-slate-900">Your membership</h3>
                        <p class="mt-6 text-base leading-7 text-slate-600">You get access to all areas without tackling with packages or options. As soon as we are developing new features you will be able to use it right away.</p>
                        <div class="mt-10 flex items-center gap-x-4">
                            <h4 class="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
                            <div class="h-px flex-auto bg-slate-100" />
                        </div>
                        <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-slate-600 sm:grid-cols-2 sm:gap-6">
                            <li v-for="feature in includedFeatures" :key="feature" class="flex gap-x-3">
                                <CheckIcon class="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                {{ feature }}
                            </li>
                        </ul>
                    </div>
                    <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div class="rounded-2xl bg-slate-50 py-10 text-center ring-1 ring-inset ring-slate-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                            <div class="mx-auto max-w-xs px-8">
                                <p class="text-base font-semibold text-slate-600">
                                    <span class="prose text-xs text-indigo-400">Save <em class="font-bold text-indigo-600">{{ yearlyDiscount }}%</em> with annual billing!</span>
                                    <div class="flex items-center justify-center w-full mb-12 flex-nowrap space-x-2">
                                        <button type="button"
                                                @click="yearly=true"
                                                class="p-2 border rounded-3xl"
                                                :class="{
                                                    'border-indigo-600 text-indigo-500 hover:bg-indigo-100 hover:text-indigo-500': yearly,
                                                    'border-slate-300 text-slate-400 hover:bg-indigo-50 hover:text-slate-500': !yearly,
                                                }">{{ $t('Yearly')}}</button>
                                        <button type="button"
                                                @click="yearly=false"
                                                class="p-2 border rounded-3xl"
                                                :class="{
                                                    'border-indigo-600 text-indigo-500 hover:bg-indigo-100 hover:text-indigo-500': !yearly,
                                                    'border-slate-300 text-slate-400 hover:bg-indigo-50 hover:text-slate-500': yearly,
                                                }">{{ $t('Monthly')}}</button>
                                    </div>
                                </p>
                                <p class="mt-6 flex items-baseline justify-center gap-x-2">
                                    <span class="text-5xl font-bold tracking-tight text-slate-900">{{ yearly ? yearlyPrice : monthlyPrice }}</span>
                                    <span class="text-sm font-semibold leading-6 tracking-wide text-slate-600">
                                        {{ currency }}
                                        <sup class="text-lg">*</sup>
                                    </span>
                                </p>
                                <a :href="route('register')" class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get access</a>
                                <p class="mt-6 text-xs leading-5 text-slate-600">Invoices and receipts available for easy company reimbursement. No credit card required - we send invoices.</p>
                                <p class="text-xs leading-5 text-indigo-800"><strong>*</strong>
                                    {{ $t('Because of the small business owner state according to § 19 UStG the seller charge no sales tax, and therefore do not show it.')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mx-auto max-w-2xl">
                    <div class="mt-6 flex flex-nowrap items-center rounded-lg bg-indigo-50 hover:bg-indigo-100 border border-indigo-100 hover:border-indigo-200 py-3 group">
                        <LightBulbIcon class="w-12 h-12 text-indigo-400 group-hover:text-indigo-600 mx-4"/>
                      <p class="text-lg leading-8 text-slate-600 group-hover:text-slate-800">Every user starts with a personal team account for <strong>free</strong>. You can use all features except for working in a team.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
